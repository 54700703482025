import React from "react";
import { Routes, Route, Outlet, Link, useLocation } from "react-router-dom";
import { lazy, Suspense } from "react";
import { Slide } from '@mui/material';
import Footer from "./Component/Footer/Footer";
import Loader from "./Component/loader/loader";

const TheLayout = lazy(() => import("./Component/Layout"));
const Home = lazy(() => import("./Component/Home/Home"));
const Layout = lazy(() => import("./Component/Layout"));
const OurServices = lazy(() => import("./Component/Pages/Our Services/ourServices"));
const AboutUs = lazy(() => import("./Component/Pages/About Us/About_Us"));
const Contact = lazy(() => import("./Component/Pages/Contact Us/Contact"));
const Product = lazy(() => import("./Component/Pages/Product/Product"));
const Disclaimer = lazy(() => import("./Component/Pages/Disclaimer/Disclaimer"));
const Privacy = lazy(() => import("./Component/Pages/Privacy Policy/Privacy"));
const Refund = lazy(() => import("./Component/Pages/Refund & Return/Refund"));
const TermCondition = lazy(() => import("./Component/Pages/Terms & Conditions/TermCondition"));
const Cancellation = lazy(() => import("./Component/Pages/Cancellation/Cancellation"));
// const TheTest = lazy(() => import('./Component/Test'));

const App = () => {
  return (
    <>
      <Suspense fallback={<div><Loader/></div>}>
        <Routes>
          <Route path="/" element={<TheLayout />}>
            <Route index element={<Home />} />
            <Route path="ourServices" element={<OurServices/>} />
            <Route path="about_us" element={<AboutUs />} />
            <Route path="contact" element={<Contact />} />
            <Route path="product" element={<Product />} />
            <Route path="disclaimer" element={<Disclaimer />} />
            <Route path="privacy-policy" element={<Privacy />} />
            <Route path="refund-policy" element={<Refund />} />
            <Route path="term-condition" element={<TermCondition />} />
            <Route path="cancellation-policy" element={<Cancellation />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
