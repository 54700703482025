import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Avatar,
  ListItemAvatar,
  Typography,
} from "@mui/material";
import React from "react";
import { animateScroll as scroll } from "react-scroll";
// import footer_bg from "./footer_bg.png";
import footer_bg from "./footer.png";
import primary from "../../style.scss";
import secondary from "../../style.scss";

// ---- Social Media Icons
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import {
  FaLinkedinIn,
  FaPinterestP,
  FaFacebookF,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";

// ---- IT service List
const companyName = {
  textDecoration: "none",
  color: "#f1f1f1",
  textDecorationLine: "underline",
  fontWeight: "600",
  "&:hover": {
    color: "#010101",
  },
};

const Service = [
  {
    title: "Web Development",
    link: "/ourServices",
  },
  {
    title: "App Development",
    link: "/ourServices",
  },
  {
    title: "Product Development",
    link: "/ourServices",
  },
  {
    title: "UI/UX Design",
    link: "/ourServices",
  },
  {
    title: "Cloud Consulting",
    link: "/ourServices",
  },
];
const Information = [
  {
    title: "About Us",
    link: "/about_us",
  },
  {
    title: "Contact Us",
    link: "/contact",
  },
  {
    title: "Our Product",
    link: "/product",
  },
  {
    title: "Our Services",
    link: "/ourServices",
  },
  {
    title: "Locations",
    link: "/contact",
  },
];
const Support = [
  {
    title: "Disclaimer",
    link: "/disclaimer",
  },
  {
    title: "Refund & Return Policy",
    link: "/refund-policy",
  },
  {
    title: "Cancellation Policy",
    link: "/cancellation-policy",
  },
  {
    title: "Terms & Conditions",
    link: "/term-condition",
  },
  {
    title: "Privacy Policy",
    link: "/privacy-policy",
  },
];



const Footer = () => {
  const pri_text_clr = primary;
  const sec_text_clr = secondary;
  const lineText = {
    display: "inline-block",
    textAlign: "center",
    position: "relative",
    fontSize: "20px",
    lineHeight: "normal",
    fontWeight: "500",
    // color: "#00a499",
    color: sec_text_clr.secondary,
    paddingLeft: "40px",
    // marginBottom: "15px",
  };
  const lineCode = {
    display: "block",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    whiteSpace: "nowrap",
    // position: "relative",
    // color: "blue",
  
    "&:before": {
      content: "''",
      position: "absolute",
      left: "0",
      top: "50%",
      transform: "translateY(-50%)",
      width: "30px",
      height: "2px",
      backgroundColor:sec_text_clr.secondary,
    },
  };
  const AvatarCss = {
    mr: 1,
    width: "30px",
    height: "30px",
    backgroundColor: "#f1f1f1",
    // border:"1px solid",
    boxShadow: "2px 2px 5px 1px #8e8f9080",
    // boxShadow:"0  5px 10px rgba(154,160,185,0.05)",

    color: sec_text_clr.secondary,
    "&:hover": {
      color: pri_text_clr.primary,
      boxShadow: "2px 2px 6px 0",
    },
  };

  const List_Text_Style = {
    fontSize: "15px",
    lineHeight: "2.0",
    position: "relative",
    // color: "#f1f1f1",
    // color: "#010101",
    color: "#028f86",
    fontWeight: "600",

    "&:after": {
      content: "''",
      position: "absolute",
      width: "100%",
      transform: "scaleX(0)",
      height: "2px",
      bottom: "0",
      left: "0",
      backgroundColor: pri_text_clr.primary,
      transformOrigin: "bottom right",
      transition: "transform 0.25s ease-out",
      color: "yellow",
    },

    "&:hover": {
      cursor: "pointer",
      marginLeft: "5px",
      // color:'yellow',
      color: sec_text_clr.secondary,

      ":after": {
        transform: "scaleX(1)",
        transformOrigin: "bottom left",
      },
    },
  };

  const box_Style = {
    backgroundImage: `url(${footer_bg})`,
    padding: "160px 0 25px",
    backgroundPosition: { md: "top", sm: "left" },
    backgroundSize: "cover",
  };

  return (
    <>
      <Box sx={{ bottom: "0" }}>
        {/* -------- Footer Top --------- */}
        <Box sx={box_Style}>
          <Container>
            <Grid container display="flex" color="GrayText" alignItems='center'>
              <Grid item md={3} xs={12} sm={6} >
                <Grid container textAlign="center">
                  <Grid
                    item
                    justifyContent="left"
                    sx={{ pl: { md: "30px", sm: "0px" } }}
                    pr={4}
                    mb={2}
                  >
                    <Typography className="logo-div" sx={{px:{sm:'16px' ,md:'0'}}}>
                      <Typography
                        component="img"
                        src="/nabhLogo.png"
                        width="100%"
                      />
                    </Typography>
                    {/* <Typography className="footer-logo-detail">
                      <Typography
                        component="p"
                        sx={{ textAlign: "justify", color:"#028f86" }}
                      >
                        Lets revolutionize your online presence with our
                        cutting-edge web solutions that blend creativity,
                        functionality, and seamless user experiences.
                      </Typography>
                    </Typography> */}
                    <Typography className="social links">
                      <List sx={{p:0}}>
                        <ListItem
                          sx={{ px: { md: "0", sx: "16px" } }}
                          className="social-icons"
                        >
                          <List
                            sx={{
                              pt: 0,
                              pb: 0,
                              display: { sm: "flex" },
                              flexGrow: 1,
                            }}
                          >
                            <ListItem
                              sx={{ padding: { xs: "0px 16px", md: "0" } }}
                            >
                              <ListItemAvatar sx={{ display: "flex" }}>
                                <Link
                                  to="https://www.facebook.com/people/Nabh-Technologies/100075929108110/"
                                  target="_blank"
                                  style={{ textDecoration: "none" }}
                                >
                                  <Avatar sx={AvatarCss}>
                                    <FaFacebookF fontSize="medium" />
                                  </Avatar>
                                </Link>
                                <Link
                                  to="https://www.youtube.com/@nabhtechnologies5032"
                                  target="_blank"
                                  style={{ textDecoration: "none" }}
                                >
                                  <Avatar sx={AvatarCss}>
                                    <FaYoutube fontSize="medium" />
                                  </Avatar>
                                </Link>

                                <Link
                                  to="https://www.linkedin.com/in/nabh-tech-6637a022a/?originalSubdomain=in"
                                  target="_blank"
                                  style={{ textDecoration: "none" }}
                                >
                                  <Avatar sx={AvatarCss}>
                                    <FaLinkedinIn
                                      fontSize="medium"
                                      fontWeight="600"
                                    />
                                  </Avatar>
                                </Link>
                                <Link
                                  to="https://www.instagram.com/nabh_technologies/"
                                  target="_blank"
                                  style={{ textDecoration: "none" }}
                                >
                                  <Avatar sx={AvatarCss}>
                                    <InstagramIcon fontSize="small" />
                                  </Avatar>
                                </Link>
                              </ListItemAvatar>
                            </ListItem>
                          </List>
                        </ListItem>
                      </List>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <Grid container textAlign="center">
                  <Grid
                    item
                    md={12}
                    display="flex"
                    justifyContent="left"
                    pl={4}
                    pr={4}
                    mb={2}
                  >
                    <List>
                      <ListItem sx={{ paddingBottom: "15px" }}>
                        <ListItemText>
                          <Typography component="div" sx={lineText}>
                            <Typography
                              component="div"
                              variant="h6"
                              sx={lineCode}
                            >
                              IT Services
                            </Typography>
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      {Service.map((item, index) => (
                        <ListItem key={index} sx={{ padding: "0px 16px" }}>
                          <Link
                            to={item.link}
                            style={{ textDecoration: "none" }}
                          >
                            <ListItemText
                              primaryTypographyProps={{
                                fontSize: "15px",
                                lineHeight: "2.0",
                                fontWeight: "600",
                              }}
                              sx={List_Text_Style}
                            >
                              {item.title}
                            </ListItemText>
                          </Link>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <Grid container textAlign="center">
                  <Grid
                    item
                    md={12}
                    display="flex"
                    justifyContent="left"
                    pl={4}
                    pr={4}
                    mb={2}
                  >
                    <List>
                      <ListItem sx={{ paddingBottom: "15px" }}>
                        <ListItemText>
                          <Typography component="div" sx={lineText}>
                            <Typography
                              component="div"
                              variant="h6"
                              sx={lineCode}
                            >
                              Information
                            </Typography>
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      {Information.map((item, index) => (
                        <ListItem key={index} sx={{ padding: "0px 16px" }}>
                          <Link
                            to={item.link}
                            smooth={true}
                            duration={1000}
                            onClick={() => scroll.scrollToTop()}
                            style={{ textDecoration: "none" }}
                          >
                            <ListItemText
                              primaryTypographyProps={{
                                fontSize: "15px",
                                lineHeight: "2.0",
                                fontWeight: "600",
                              }}
                              sx={List_Text_Style}
                            >
                              {item.title}
                            </ListItemText>
                          </Link>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <Grid container textAlign="center">
                  <Grid
                    item
                    md={12}
                    display="flex"
                    justifyContent="left"
                    pl={4}
                    pr={4}
                    mb={2}
                  >
                    <List>
                      <ListItem sx={{ paddingBottom: "15px" }}>
                        <ListItemText>
                          <Typography component="div" sx={lineText}>
                            <Typography
                              component="div"
                              variant="h6"
                              sx={lineCode}
                            >
                              Helpful Links
                            </Typography>
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      {Support.map((item, index) => (
                        <ListItem key={index} sx={{ padding: "0px 16px" }}>
                          <Link
                            to={item.link}
                            style={{ textDecoration: "none" }}
                          >
                            <ListItemText
                              primaryTypographyProps={{
                                fontSize: "15px",
                                lineHeight: "2.0",
                                fontWeight: "600",
                              }}
                              sx={List_Text_Style}
                            >
                              {item.title}
                            </ListItemText>
                          </Link>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
        {/* -------- Footer Bottom --------- */}
        <Box
          sx={{
            backgroundColor: pri_text_clr.primary,
            color: "#fafafa",
            borderTop: "1px solid #fafafa99",
          }}
        >
          <Container>
            <Grid
              container
              direction="row"
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <Grid item md={6} sx={{ alignItems: "center", display: "flex" }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontSize: "1rem",
                    padding: "16px 0px 10px",
                    textAlign: "center",
                  }}
                >
                  © 2023{" "}
                  <span>
                    <Link to='/' style={companyName}>
                      NabhTechnologies
                    </Link>
                  </span>
                  . All Rights Reserved
                </Typography>
              </Grid>
              <Grid item md={5}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    whiteSpace: "nowrap",
                  }}
                >
                  <List sx={{ display: "flex", p: "0" }}>
                    <Link
                      to="./privacy-policy"
                      // target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem sx={{ color: "#f1f1f1" }}>
                        <ListItemText>Policy</ListItemText>
                      </ListItem>
                    </Link>
                    <Link
                      to="./contact"
                      // target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem sx={{ color: "#f1f1f1" }}>
                        <ListItemText>Contact Us</ListItemText>
                      </ListItem>
                    </Link>
                    <Link
                      to="./disclaimer"
                      // target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem sx={{ color: "#f1f1f1" }}>
                        <ListItemText>Disclaimer</ListItemText>
                      </ListItem>
                    </Link>
                  </List>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
