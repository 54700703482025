import React from 'react';
const Loader = () => {
    return (
        <>
            <div
                className="loader"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    flexDirection: 'column'
                }}
            >
                <div className="img" style={{ position: 'relative' }}>
                    <div>
                        <img src={process.env.PUBLIC_URL + '/NabhTech_Logo.png'} alt="Loading...." height="65px" />
                    </div>
                    <div
                        className="lds-ellipsis"
                        style={{ position: 'absolute', top: '130%', left: '60%', transform: 'translate(-50% , -50%' }}
                    >
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Loader;
